import logo from './logo.uhon.tech.svg';
import './App.css';
import React from "react"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <img alt="uhon.tech Logo" src={logo} className={"App-logo"} title={"uhon.tech Logo"}/>
        </div>
        <address>
          Bönirainstrasse 13<br/>
          8800 Thalwil
        </address>
        <p>
          Web Solutions - As individual as you are
        </p>
        <a
          className="App-link"
          href="mailto:admin@uhon.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </a>
      </header>
    </div>
  );
}

export default App;
